'use client';

import {
  BarChart3Icon,
  BookOpenText,
  FilePenLine,
  Headset,
  Send,
  Settings,
} from 'lucide-react';
import * as React from 'react';
import useDashboard from '~/app/hooks/useDashboard';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from '~/components/ui/sidebar';

import { NavMain } from './NavMain';
import { NavSecondary } from './NavSecondary';
import { NavSettings } from './NavSettings';
import { NavTeam } from './NavTeam';
import { NavUser } from './NavUser';

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { activeTeam, currentProjectId } = useDashboard();

  const projectSpecificLink = (link: string) => {
    let baseURL = `/teams/${activeTeam?.teamId}/projects`;
    if (currentProjectId) {
      baseURL += `/${currentProjectId}${link}`;
    } else {
      baseURL += '/intro';
    }
    return baseURL;
  };

  const data = {
    navMain: [
      {
        title: 'Analytics',
        url: `/teams/${activeTeam?.teamId}/projects/intro`,
        icon: BarChart3Icon,
        isActive: true,
        items: [
          {
            title: 'Overview',
            url: projectSpecificLink('/overview'),
          },
          {
            title: 'Activity',
            url: projectSpecificLink('/activity'),
          },
          {
            title: 'Users',
            url: projectSpecificLink('/users'),
          },
        ],
      },
      {
        title: 'Forms',
        url: `/teams/${activeTeam?.teamId}`,
        icon: FilePenLine,
      },
    ],
    settings: [
      {
        title: 'Settings',
        icon: Settings,
        isActive: false,
        url: `/teams/${activeTeam?.teamId}/settings`,
        items: [
          {
            title: 'General',
            url: `/teams/${activeTeam?.teamId}/settings`,
          },
          {
            title: 'People',
            url: `/teams/${activeTeam?.teamId}/settings/member`,
          },
          // {
          //   title: 'Billing',
          //   url: `/teams/${activeTeam?.teamId}/billing`,
          // },
        ],
      },
    ],
    navSecondary: [
      {
        title: 'Docs',
        url: 'https://docs.formo.so',
        icon: BookOpenText,
      },
      {
        title: 'Support',
        url: 'https://help.formo.so',
        icon: Headset,
      },
      {
        title: 'Feedback',
        url: 'https://formo.canny.io',
        icon: Send,
      },
    ],
  };

  return (
    <Sidebar collapsible="icon" variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="sm" asChild>
              <NavTeam />
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavSettings settings={data.settings} />
        <NavSecondary items={data.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
