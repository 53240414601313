'use client';

import { ChevronsUpDown } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { FC, useState } from 'react';
import useDashboard from '~/app/hooks/useDashboard';
import useDashboardModal from '~/app/hooks/useDashboardModal';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '~/components/ui/sidebar';
import { Skeleton } from '~/components/ui/skeleton';
import { LAST_ACTIVE_PROJECT_KEY_NAME } from '~/constants/projects';
import { cn } from '~/lib/utils';
import { formoSessionStorage } from '~/utils/storage';

import { Avatar, Plus } from '../../common';

const workspaceItemClass =
  'flex justify-start items-center gap-2.5 py-1.5 px-2.5 cursor-pointer hover:bg-gray-100/60 focus:bg-surface-brand-light rounded-sm';

interface WorkspaceActionItemProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
}

const WorkspaceActionItem: FC<WorkspaceActionItemProps> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <div className={workspaceItemClass} onClick={onClick}>
      {icon}
      <span className="b3 font-normal text-content-secondary">{label}</span>
    </div>
  );
};

const WorkspaceNameSkeleton: FC = () => {
  return (
    <div className="flex animate-pulse items-center rounded-[10px] py-[4px] w-full">
      <Skeleton className="h-3 w-[120px] rounded-[11px] bg-content-secondary" />
    </div>
  );
};

const WorkspaceItemSkeleton: FC = () => {
  return (
    <div className="flex animate-pulse items-center rounded-[10px] px-[5px] py-[8px]">
      <Skeleton className="aspect-square h-6" />
      <Skeleton className="ml-2.5 h-4 flex-1" />
      <Skeleton className="ml-6 h-6 w-[30px] rounded-[11px]" />
    </div>
  );
};

export function NavTeam() {
  const { isMobile } = useSidebar();
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);

  const { activeTeam, teams, refetchTeams, isLoadingTeams } = useDashboard();
  const { openModal } = useDashboardModal();
  const router = useRouter();

  const onCreateTeam = () => {
    if (!activeTeam || isLoadingTeams) return;
    openModal({
      type: 'create_team',
      onSubmit: async (team) => {
        await refetchTeams();
        router.push(`/teams/${team.id}`);
      },
    });
  };

  const workspaceActions = [
    {
      icon: <Plus size={16} />,
      label: 'New workspace',
      onClick: onCreateTeam,
    },
  ];

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu open={isOpenUserMenu} onOpenChange={setIsOpenUserMenu}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className={cn(
                isOpenUserMenu && 'bg-surface-tertiary',
                'rounded-lg bg-none flex h-auto w-full justify-between focus:outline-none',
              )}
            >
              <div className="flex gap-2 items-center max-w-[80%]">
                <Avatar name={activeTeam?.team?.teamName?.[0] ?? ''} />
                <div className="max-w-[80%]">
                  {isLoadingTeams ? (
                    <WorkspaceNameSkeleton />
                  ) : (
                    <div className="mr-auto text-left truncate b3 font-semibold text-content-primary">
                      {activeTeam?.team?.teamName}
                    </div>
                  )}
                  <div className="mr-auto text-left truncate s1 text-content-secondary font-normal">
                    Free plan
                  </div>
                </div>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            side={isMobile ? 'bottom' : 'top'}
            sideOffset={4}
            className="p-1 border-0 w-[224px] min-w-[var(--radix-select-trigger-width)] rounded-lg !shadow-elevation-below"
          >
            <DropdownMenuLabel>
              <span className="font-semibold s1 text-content-secondary">
                Workspaces
              </span>
            </DropdownMenuLabel>
            <DropdownMenuGroup>
              {isLoadingTeams
                ? [...Array(3)].map((_, i) => <WorkspaceItemSkeleton key={i} />)
                : teams?.map(({ teamId, team }) => (
                    <DropdownMenuItem
                      key={teamId}
                      className="px-2 py-1.5 text-base font-normal text-content-primary hover:bg-gray-100/60 focus:bg-gray-100/60"
                      onClick={() => {
                        router.replace(`/teams/${teamId}`);
                        formoSessionStorage.setItem('activeTeamId', teamId);
                        formoSessionStorage.removeItem(
                          LAST_ACTIVE_PROJECT_KEY_NAME,
                        );
                        // clear all _rtk keys
                        formoSessionStorage.removeMatch(/^(.*)_rtk$/);
                      }}
                    >
                      <Link
                        href={`/teams/${teamId}`}
                        className="flex justify-start items-center"
                      >
                        <Avatar
                          classNames="mr-2 h-5 w-5 text-base font-semibold border-none"
                          name={team?.teamName?.[0]?.toUpperCase()}
                        />

                        <span className="block max-w-[150px] truncate b3">
                          {team.teamName}
                        </span>
                      </Link>
                    </DropdownMenuItem>
                  ))}
            </DropdownMenuGroup>
            <div className="my-1 flex h-[1px] w-full items-center justify-center bg-content-divider"></div>
            <div>
              {workspaceActions.map((action, index) => (
                <WorkspaceActionItem
                  key={index}
                  icon={action.icon}
                  label={action.label}
                  onClick={action.onClick}
                />
              ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
