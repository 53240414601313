'use client';

import { CreateTeamRequest, CreateTeamResponse } from '@formo/shared';
import { useMutation } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { ScreenScalingMessage } from '~/app/_components/Builder/components/ScreenScalingMessage';
import { Sidebar } from '~/app/_components/dashboard/Sidebar';
import DashboardProvider from '~/app/context/DashboardContext';
import DashboardModalProvider from '~/app/context/DashboardModalContext';
import { IntercomProvider } from '~/app/context/IntercomContext';
import { useAuth } from '~/app/hooks/AuthProvider';
import useDashboard from '~/app/hooks/useDashboard';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { SidebarInset, SidebarProvider } from '~/components/ui/sidebar';
import { useToast } from '~/components/ui/use-toast';
import { MIN_WIDTH_SCREEN } from '~/constants/common';
import useOptimalWidth from '~/hooks/use-optimal-width';
import client from '~/lib/client';

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID!;

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <DashboardProvider>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <DashboardModalProvider>
          <DashboardMain>{children}</DashboardMain>
        </DashboardModalProvider>
      </IntercomProvider>
    </DashboardProvider>
  );
}

function DashboardMain({ children }: { children: React.ReactNode }) {
  const { isLoadingTeams, teams, refetchTeams } = useDashboard();
  const { user } = useAuth();
  const isOptimalWidth = useOptimalWidth();
  const pathname = usePathname();

  const teamApi = useMutation<
    CreateTeamResponse,
    Error,
    CreateTeamRequest['body']
  >({
    mutationFn: async (data) => (await client.post('/api/teams', data)).data,
  });
  const { toast } = useToast();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!user) return;
    const workspaceName = e.currentTarget['workspace-name'].value as string;
    if (!workspaceName) return;
    try {
      await teamApi.mutateAsync({
        name: workspaceName.trim(),
        tier: 'Starter',
      });
      toast({
        title: 'Workspace created successfully',
        description: 'Welcome to Formo!',
      });
      refetchTeams();
    } catch (error: any) {
      toast({
        title: 'Failed to create workspace',
        description: error.message || 'An error occurred',
      });
    }
  };

  if (!isLoadingTeams && teams.length === 0) {
    return (
      <form
        className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white shadow-thick px-5 py-6 flex flex-col max-w-[520px] w-full rounded-10 border-black/20 border"
        onSubmit={onSubmit}
      >
        <h1 className="text-black text-[20px] font-medium">
          Hi {user?.email}!
        </h1>
        <p className="text-gray-600 mt-[18px]">
          Let's create a new workspace to get started
        </p>
        <Input
          name="workspace-name"
          className="shadow-[0_0_4px_0_rgba(134,219,46,1)] mt-3 focus-visible:ring-0 focus-visible:ring-offset-0 border-1.5 border-green-600 focus-visible:border-green-600"
          placeholder="Name your workspace"
          disabled={teamApi.isPending}
        />
        <Button
          className="border-1.5 border-green-400 shadow-[1px_2px_7px_0_rgba(0,0,0,0.04)] rounded-lg px-5 py-3 text-base font-medium text-black mt-[18px] ml-auto"
          disabled={teamApi.isPending || !user}
        >
          Create workspace
        </Button>
      </form>
    );
  }

  return (
    <div className="flex max-h-screen backdrop-blur-md bg-black/1">
      {window.innerWidth && isOptimalWidth ? (
        <SidebarProvider
          open={pathname.split('/').pop() === 'upgrade' ? false : undefined}
        >
          <Sidebar
            collapsible={
              pathname.split('/').pop() === 'upgrade' ? 'offcanvas' : 'icon'
            }
          />

          <SidebarInset className="border border-content-border rounded-lg bg-surface-primary shadow-none">
            {children}
          </SidebarInset>
        </SidebarProvider>
      ) : (
        <ScreenScalingMessage minWidth={MIN_WIDTH_SCREEN} />
      )}
    </div>
  );
}
